import * as WixDataApi from 'wix-data-core/lib/data-api'

export const createWixData = elementorySupportInstance => {
  return new WixDataApi(createWixDataProxy(elementorySupportInstance))
}
const createWixDataProxy = elementorySupportInstance => {
  return {
    save: function save() {
      return elementorySupportInstance.getJSON('/wix/data-web.jsw/save.ajax', arguments)
    },
    insert: function insert() {
      return elementorySupportInstance.getJSON('/wix/data-web.jsw/insert.ajax', arguments)
    },
    update: function update() {
      return elementorySupportInstance.getJSON('/wix/data-web.jsw/update.ajax', arguments)
    },
    get: function get() {
      return elementorySupportInstance.getJSON('/wix/data-web.jsw/get.ajax', arguments)
    },
    remove: function remove() {
      return elementorySupportInstance.getJSON('/wix/data-web.jsw/remove.ajax', arguments)
    },
    find: function find() {
      return elementorySupportInstance.getJSON('/wix/data-web.jsw/find.ajax', arguments)
    },
    count: function count() {
      return elementorySupportInstance.getJSON('/wix/data-web.jsw/count.ajax', arguments)
    },
    bulkInsert: function bulkInsert() {
      return elementorySupportInstance.getJSON('/wix/data-web.jsw/bulkInsert.ajax', arguments)
    },
    queryReferenced: function queryReferenced() {
      return elementorySupportInstance.getJSON('/wix/data-web.jsw/queryReferenced.ajax', arguments)
    },
    insertReference: function insertReference() {
      return elementorySupportInstance.getJSON('/wix/data-web.jsw/insertReference.ajax', arguments)
    },
    removeReference: function removeReference() {
      return elementorySupportInstance.getJSON('/wix/data-web.jsw/removeReference.ajax', arguments)
    },
    isReferenced: function isReferenced() {
      return elementorySupportInstance.getJSON('/wix/data-web.jsw/isReferenced.ajax', arguments)
    },
    getSchema: function getSchema() {
      return elementorySupportInstance.getJSON('/wix/data-web.jsw/getSchema.ajax', arguments)
    },
  }
}
